body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

/* ------------- */

.test-text {
    font-size: 12px;
    color: black;
}

/* ------------- */

@import url("https://fonts.googleapis.com/css?family=Roboto|Staatliches");

.footer {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100%;
  background-color: #1976d2;
  color: whitesmoke;
  
  height: 30px;
  font-size: 2.8vh;
  display: flex;

  align-items: center;

  font-family: "Staatliches", serif;
}

.footerText {
  position: fixed;
  font-size: 20px;
  right: 15px;
}

/* ------------- */

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.parentElement {
  position: relative;
  width: 50px;
  height: 100%;
}

.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}

/* ------------- */

.generateButton {
  position: fixed;
  bottom: 10vh;
}

/* ------------- */

.aboutButton {
  position: fixed;
  left: 0;
  top: 0;
  margin: 4px;
}

/* ------------- */

.diagram {
  width: 90%;
}

/* ------------- */

.aboutText {
  color: black;
  font-size: 12px;
}